import { render, staticRenderFns } from "./subMenuList.vue?vue&type=template&id=63e7f9cd&scoped=true&"
import script from "./subMenuList.vue?vue&type=script&lang=js&"
export * from "./subMenuList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e7f9cd",
  null
  
)

export default component.exports